<template>
  <v-card
    outlined
    class="d-flex flex-column"
  >
    <v-card-title>
      <h5>Filter bearbeiten</h5>
      <v-spacer />
      <v-btn
        v-if="canClose"
        icon
        @click="close"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text class="py-2 dialog-content-overflow">
      <v-expansion-panels
        v-model="panel"
        accordion
        flat
      >
        <v-expansion-panel class="my-1 expansion-panel-border">
          <v-expansion-panel-header class="px-2">
            <span class="text-body-1">
              Bearbeitung
              <v-badge
                color="red"
                :value="isEditFilterActive"
                :content="editFilterActiveCount"
                class="ml-1"
              />
            </span>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="filter-panel">
            <v-select
              v-model="filters.currentStatus"
              :items="statusList"
              label="Status"
              class="mb-4"
              hide-details
              clearable
              outlined
              dense
            />
            <v-select
              v-if="isExpert"
              v-model="filters.assignedUser"
              :items="assignedUserList"
              label="Verantwortlicher Mitarbeiter"
              hide-details
              clearable
              outlined
              dense
            />
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel class="my-1 expansion-panel-border">
          <v-expansion-panel-header class="px-2">
            <span class="text-body-1">
              Kontaktaktionen
              <v-badge
                color="red"
                :value="isConversionFilterActive"
                :content="conversionFilterActiveCount"
                class="ml-1"
              />
            </span>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="filter-panel">
            <span class="text-body-2">Nur Kontakte mit Ködertyp anzeigen</span>
            <div>
              <v-checkbox
                v-for="({key, label, icon},index) of conversionFilters"
                :key="key"
                v-model="filters[key]"
                hide-details
                :class="{'mt-0':index===0}"
              >
                <template v-slot:label>
                  <div>
                    <v-icon small>
                      {{ icon }}
                    </v-icon>
                    {{ label }}
                  </div>
                </template>
              </v-checkbox>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <Feature :feature-slug="featureNames.POSTAL_SHIPPING">
          <v-expansion-panel
            v-if="isExpert"
            class="my-1 expansion-panel-border"
          >
            <v-expansion-panel-header class="px-2">
              <span class="text-body-1">
                Versand Option
                <v-badge
                  color="red"
                  :value="filters.postalShipping"
                  content="1"
                  class="ml-1"
                />
              </span>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="filter-panel">
              <span class="text-body-2">Nur Kontakte mit</span>
              <v-checkbox
                v-model="filters.postalShipping"
                class="mt-0"
                hide-details
                label="postalischem Versand"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </Feature>

        <v-expansion-panel
          v-if="isExpert"
          class="my-1 expansion-panel-border"
        >
          <v-expansion-panel-header class="px-2">
            <span class="text-body-1">
              Kontaktart
              <v-badge
                color="red"
                :value="isContactTypeFilterActive"
                content="1"
                class="ml-1"
              />
            </span>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="py-0 filter-panel">
            <v-select
              v-model="filterContactType"
              :items="originList"
              label="Kontaktart"
              class="mb-4"
              hide-details
              outlined
              dense
            />
          </v-expansion-panel-content>
        </v-expansion-panel>

        <Feature :feature-slug="featureNames.ACQUISITION_BOOSTER">
          <v-expansion-panel
            class="my-1 expansion-panel-border"
          >
            <v-expansion-panel-header class="px-2">
              <span class="text-body-1">
                Akquise-Booster Status
                <v-badge
                  color="red"
                  :value="isInvitationFilterActive"
                  content="1"
                  class="ml-1"
                />
              </span>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="py-0 filter-panel">
              <v-select
                v-model="filters.invited"
                :items="invitedLandingpageSlugs"
                label="Thema"
                class="mb-4"
                hide-details
                clearable
                outlined
                dense
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </Feature>
        <v-expansion-panel
          class="my-1 expansion-panel-border"
        >
          <v-expansion-panel-header class="px-2">
            <span class="text-body-1">
              Kontaktquellen
              <v-badge
                color="red"
                :value="isSourceFilterActive"
                content="1"
                class="ml-1"
              />
            </span>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="py-0 filter-panel">
            <v-select
              v-model="filters.source"
              :items="trafficSourceList"
              label="Quelle"
              class="mb-4"
              hide-details
              clearable
              outlined
              dense
            />
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel
          class="my-1 expansion-panel-border"
        >
          <v-expansion-panel-header class="px-2">
            <span class="text-body-1">
              Downloads
              <v-badge
                color="red"
                :value="isDownloadLandingpageFilterActive"
                content="1"
                class="ml-1"
              />
            </span>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="py-0 filter-panel">
            <v-select
              v-model="filters.downloadLandingpage"
              :items="downloadLandingpageList"
              label="Thema"
              class="mb-4"
              hide-details
              clearable
              outlined
              dense
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <p class="mt-8 text-caption">
        Info: Das Setzen / Ändern eines Filters wirkt sich sofort
        aus und wird gespeichert, bis er wieder geändert oder
        zurückgesetzt wird.
      </p>
    </v-card-text>

    <v-spacer />

    <v-card-actions class="justify-end d-flex">
      <v-btn
        right
        :disabled="activeFiltersCount<=0"
        small
        color="error"
        class="w-full"
        @click="resetFilters()"
      >
        Alle Filter zurücksetzen
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { Feature } from 'feature-config'
import featureNames from '@/lib/featureNames'
import { statusForSelect as statusList } from '@/lib/leadStatus'
import { originForSelect as originList } from './lib/leadOrigin'
import { isExpertUser } from '@/lib/productTypes'
import { TOOLS_WITH_DOWNLOADS } from '@/lib/tools'
import { getActiveFilterCount, persistAllFilters, restoreAllFilters } from '../helper/filter'

export default {
  components: {
    Feature
  },
  props: {
    hasTool: {
      type: Function,
      default: () => {}
    },
    assignedUserList: {
      type: Array,
      default: () => []
    },
    invitedLandingpageSlugs: {
      type: Array,
      default: () => []
    },
    trafficSourceList: {
      type: Array,
      default: () => []
    },
    downloadLandingpageList: {
      type: Array,
      default: () => []
    },
    canClose: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      filters: {
        currentStatus: null,
        assignedUser: null,
        invited: null,
        source: null,
        downloadLandingpage: null,
        leadOriginConversion: true,
        leadOriginImport: true,
        postalShipping: false,
        ...TOOLS_WITH_DOWNLOADS.reduce((obj, { key }) => ({ ...obj, [key]: false }), {})
      },
      filterContactType: 'leadOriginAll',
      featureNames,
      activeFiltersCount: 0,
      statusList,
      originList,
      panel: -1
    }
  },

  computed: {
    isExpert () {
      return isExpertUser(this.$auth.user)
    },
    conversionFilters () {
      return TOOLS_WITH_DOWNLOADS.filter(({ type }) => this.hasTool(type))
    },
    editFilterActiveCount () {
      return [
        this.filters.currentStatus,
        this.filters.assignedUser
      ].filter(Boolean).length
    },
    conversionFilterActiveCount () {
      return [...this.conversionFilters.map(({ key }) => this.filters[key])].filter(Boolean).length
    },
    contactTypeFilterActiveCount () {
      return [
        this.filters.leadOriginConversion,
        this.filters.leadOriginImport
      ].filter(Boolean).length
    },
    isConversionFilterActive () {
      return this.conversionFilterActiveCount > 0
    },
    isEditFilterActive () {
      return this.editFilterActiveCount > 0
    },
    isContactTypeFilterActive () {
      return this.contactTypeFilterActiveCount === 1
    },
    isInvitationFilterActive () {
      return Boolean(this.filters.invited)
    },
    isSourceFilterActive () {
      return Boolean(this.filters.source)
    },
    isDownloadLandingpageFilterActive () {
      return Boolean(this.filters.downloadLandingpage)
    }
  },

  watch: {
    filters: {
      handler () {
        persistAllFilters({ isExpert: this.isExpert, filters: this.filters, availableTools: this.conversionFilters })
        this.activeFiltersCount = getActiveFilterCount({ isExpert: this.isExpert, availableTools: this.conversionFilters })
        this.$emit('filtersChanged', {
          filters: this.filters,
          activeFiltersCount: this.activeFiltersCount
        })
      },
      deep: true
    },
    filterContactType (newValue) {
      if (newValue === 'leadOriginAll') {
        this.filters.leadOriginConversion = true
        this.filters.leadOriginImport = true
      } else if (newValue === 'leadOriginImport') {
        this.filters.leadOriginConversion = false
        this.filters.leadOriginImport = true
      } else {
        this.filters.leadOriginConversion = true
        this.filters.leadOriginImport = false
      }
    }
  },

  created () {
    this.filters = restoreAllFilters({ isExpert: this.isExpert, availableTools: this.conversionFilters })
    this.activeFiltersCount = getActiveFilterCount({ isExpert: this.isExpert, availableTools: this.conversionFilters })
    this.filterContactType = this.mapFilterContactType()
  },

  mounted () {
    this.setInitialPanel()
  },

  methods: {
    setInitialPanel () {
      if (Object.keys(this.$route.query).includes('filter')) {
        this.panel = 1
      }
    },
    mapFilterContactType () {
      if (this.filters.leadOriginImport && this.filters.leadOriginConversion) return 'leadOriginAll'
      if (this.filters.leadOriginImport) return 'leadOriginImport'
      return 'leadOriginConversion'
    },
    close () {
      this.$emit('close')
      if (Object.keys(this.$route.query).includes('filter')) {
        this.$router.push('/leads')
      }
    },
    resetFilters () {
      this.filters.currentStatus = null
      this.filters.assignedUser = null
      this.filters.invited = null
      this.filters.source = null
      this.filters.downloadLandingpage = null
      this.filters.leadOriginConversion = true
      this.filters.leadOriginImport = true
      this.filters.postalShipping = false
      this.conversionFilters.forEach(({ key }) => { this.filters[key] = false })
      this.filterContactType = this.mapFilterContactType()
    }
  }

}
</script>

<style scoped>
.dialog-content-overflow{
  max-height: 100%;
  overflow: scroll
}
.gap{
  gap: 10px;
}
.filter-width{
  width: 240px
}

</style>
<style>
.filter-panel > .v-expansion-panel-content__wrap{
  padding-left: 7px;
  padding-right: 7px;
  padding-top: 0px;
  padding-bottom: 10px;
}
</style>
